import React from "react";
import { Table, TableBody, TableCell, TableRow, TableContainer, Paper } from "@mui/material";
import dayjs from "dayjs";
import { thousandsSeparator } from "../../../untils/shortcut";
import moment from "moment-timezone";

const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const ReportDepositMobile = ({ transactions, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, getStatusColor }) => (
    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
            <TableBody>
                {transactions.length === 0 ? (
                    <TableRow>
                        <TableCell colSpan={6} align="center">
                            No records to display
                        </TableCell>
                    </TableRow>
                ) : (
                    transactions
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((transaction) => (
                            <TableRow key={transaction.id}>
                                <TableCell>
                                    <div className="transaction-card">
                                        <p><strong>Client Transaction ID:</strong> {transaction.clientTransactionId}</p>
                                        <p><strong>System Transaction ID:</strong> {transaction.systemTransactionId}</p>
                                        <p><strong>Merchant Name:</strong> {transaction.merchant.name}</p>
                                        <p><strong>Amount:</strong> {thousandsSeparator(transaction.amount)}</p>
                                        <p><strong>Amount With Fee:</strong> {thousandsSeparator(transaction.amountWithFee)}</p>
                                        <p><strong>Status:</strong> <span style={{ color: getStatusColor(transaction.status), fontWeight: 'bold' }}>{transaction.status}</span></p>
                                        <p><strong>Date Created:</strong> {moment(transaction.dateCreated).tz(userTimeZone).format("DD/MMM/YYYY HH:mm:ss")}</p>
                                        <p><strong>Date Updated:</strong> {moment(transaction.dateUpdated).tz(userTimeZone).format("DD/MMM/YYYY HH:mm:ss")}</p>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))
                )}
            </TableBody>
        </Table>
    </TableContainer>
);

export default ReportDepositMobile;