import React from 'react';
import { Grid } from "@mui/material";

// components
import PageHeader from '../components/common/PageHeader';
import WithdrawBroker from '../components/withdraw/WithdrawBroker';

function WithdrawBrokerPage() {
    return (
        <Grid>
            <PageHeader title="Withdraw Broker" path="Withdraw Broker" />
            <Grid sx={{ p: 3 }}>
                <WithdrawBroker />
            </Grid>
        </Grid>
    );
}

export default WithdrawBrokerPage;