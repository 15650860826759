import React, { useState, useEffect } from 'react'
import {
    Grid,
    Box,
    Paper,
    Stack,
    InputAdornment,
    Button,
    FormControl,
    InputLabel,
    OutlinedInput,
    IconButton,
    Typography,
    useMediaQuery,
    Container,
    TextField,
} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

//componant
// import PageHeader from '../components/common/PageHeader';

//theme
import { LoginButton, LoginTextField } from '../Theme.jsx';
import { color } from '@mui/system';

//service
import { POST_AUTH, POST, login } from '../service/service.js';

//sweetalert2
import Swal from 'sweetalert2';

function Login() {

    const [state, setState] = useState({
        token: null,
        login: false,
    });

    const [values, setValues] = useState({
        showPassword: false,
    });

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        const token = window.localStorage.getItem('token_Login');
        if (token) {
            window.location.replace('/');
        }
    }, []);

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            Login();
        }
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const Login = async () => {

        try {
            if (state.token === null) {

                let res = await POST_AUTH(login, { username, password });
                if (res.success) {
                    window.localStorage.setItem("token_Login", res.apiToken);
                    window.localStorage.setItem("role", 'admin');
                    window.localStorage.setItem("username", res.name);
                    window.localStorage.setItem("user_id", res.id);
                    setState({ ...state, token: res.apiToken, login: true });
                    window.location.replace("/");
                } else {
                    // alert(res.message);
                    Swal.fire({
                        icon: 'warning',
                        text: `${res.message}`,
                        confirmButtonText: 'ตกลง',
                    })
                }
            }
        } catch (error) {
            alert(error);
        }
    };

    const token = window.localStorage.getItem('token_Login');
    if (token) {
        return null;
    }

    return (
        <Grid>
            <Box>
                <Paper elevation={0} >
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <Container maxWidth="md">
                                <Box
                                    sx={{
                                        height: "100vh",
                                        backgroundColor: "#fff",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Stack spacing={2} xs={12} md={4}>
                                        <Typography variant="h5" color="#000"
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            Admin Happy Pay
                                        </Typography>
                                        <Box
                                            sx={{
                                                width: 350,
                                                maxWidth: '100%',
                                            }}
                                        >
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel htmlFor="outlined-adornment-username" >ชื่อผู้ใช้งาน</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-username"
                                                    label="ชื่อผู้ใช้งาน"
                                                    value={username}
                                                    onChange={(e) => setUsername(e.currentTarget.value)}
                                                    onKeyDown={handleKeyPress}
                                                />
                                            </FormControl>
                                        </Box>
                                        <Box
                                            sx={{
                                                width: 350,
                                                maxWidth: '100%',
                                            }}
                                        >
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel htmlFor="outlined-adornment-password" >รหัสผ่าน</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-password"
                                                    label="รหัสผ่าน"
                                                    type={values.showPassword ? 'text' : 'password'}
                                                    value={password}
                                                    onChange={(e) => setPassword(e.currentTarget.value)}
                                                    onKeyDown={handleKeyPress}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Box>
                                        <LoginButton
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                minWidth: "100%",
                                                minHeight: "10%",
                                                mt: 2,
                                                fontWeight: "bold",
                                                borderRadius: "50px",
                                            }}
                                            variant="contained"
                                            onClick={Login}
                                        >
                                            เข้าสู่ระบบ
                                        </LoginButton>
                                    </Stack>
                                </Box>
                            </Container>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Grid>
    )
}

export default Login;