import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";

//calendar
import dayjs from "dayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

//theme
import { SearchButton } from '../../Theme.jsx'

//icons
import { SearchTextIcon } from '../../untils/icons.js'

//services
import { POST, TRANSACTIONPROVIDER, MERCHANT, GET } from "../../service/service.js"
import moment from "moment-timezone";

function CallbackProviderList() {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [transactions, setTransactions] = useState([]);
  const [broker, setBroker] = useState("All");
  const [brokerList, setBrokerList] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf("month").tz(userTimeZone));
  const [endDate, setEndDate] = useState(moment().endOf('day').tz(userTimeZone));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // State for dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [parseBroker, setParseBroker] = useState(null);
  const [parsePayload, setParsePayload] = useState(null);

  useEffect(() => {
    getTransactionProvider({ merchant_name: broker, startDate, endDate });
    getBroker();
  }, []);

  const getTransactionProvider = async ({ merchant_name: broker, startDate, endDate }) => {
    try {
      const res = await POST(TRANSACTIONPROVIDER, {
        merchant_name: broker,
        startDate,
        endDate,
      });
      if (res.success) {
        const sortedData = res.result.sort((a, b) =>
          new Date(b.dateCreated) - new Date(a.dateCreated)
      );
        setTransactions(sortedData);
      } else {
        alert(res.message);
      }
    } catch (error) {
      alert("Error fetching transactions");
    }
  };

  const getBroker = async () => {
    try {
      const res = await GET(MERCHANT);
      if (res.success) {
        setBrokerList(res.result);
      } else {
        alert(res.message);
      }
    } catch (error) {
      alert("Error fetching brokers");
    }
  };

  const handleSearch = () => {
    getTransactionProvider({ merchant_name: broker, startDate, endDate });
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };

  const handleRowClick = (transaction) => {
    try {
      const parsedPayload = JSON.parse(transaction.payload);
      setParseBroker(transaction.merchantName);
      setParsePayload(parsedPayload);
      setOpenDialog(true);
    } catch (error) {
      alert("Error parsing payload");
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setParseBroker(null);
    setParsePayload(null);
  };

  const handleChangeStartDate = (startValue) => {
    if (startValue) {
      if (startValue.isAfter(endDate)) {
        setEndDate(startValue.add(1, "second"));
      } else if (startValue.isSame(endDate)) {
        setEndDate(startValue.add(1, "second"));
      }
      setStartDate(startValue);
    }
  };

  const handleChangeEndDate = (endValue) => {
    if (endValue) {
      if (endValue.isBefore(startDate)) {
        setStartDate(endValue.subtract(1, "second"));
      } else if (endValue.isSame(startDate)) {
        setStartDate(endValue.subtract(1, "second"));
      }
      setEndDate(endValue);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid>
        <Box display="flex" justifyContent="center">
          <Paper sx={{ width: '90%', my: 1 }} elevation={0}>
            <Typography variant="caption" color="#2996D1" fontSize={18} display="flex" fontWeight="bold" alignItesm="center" sx={{ pt: 2, px: 1.5 }}>
              ค้นหา
            </Typography>
            <Grid item xs={12} sx={{ p: 1.5 }}>
              <Grid container spacing={2} marginBottom={2} alignItems="center">
                <Grid item xs={12} sm={2.5}>
                  <FormControl fullWidth>
                    <InputLabel>Broker</InputLabel>
                    <Select
                      value={broker}
                      onChange={(e) => setBroker(e.target.value)}
                      label="Broker"
                    >
                      <MenuItem value="All">All</MenuItem>
                      {brokerList.map((item) => (
                        <MenuItem key={item.name} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <DesktopDatePicker
                    label="วันที่เริ่มต้น"
                    inputFormat="DD/MMM/YYYY HH:mm:ss"
                    value={startDate}
                    onChange={handleChangeStartDate}
                    maxDate={dayjs()}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <DesktopDatePicker
                    label="วันที่สิ้นสุด"
                    inputFormat="DD/MMM/YYYY HH:mm:ss"
                    value={endDate}
                    onChange={handleChangeEndDate}
                    minDate={startDate}
                    maxDate={dayjs()}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Grid>
                <Grid item xs={12} sm={1} md={2} sx={{ mt: 1 }}>
                  <SearchButton width="auto" variant="outlined" onClick={handleSearch} startIcon={<SearchTextIcon />}>
                    ค้นหา
                  </SearchButton>
                </Grid>
              </Grid>
              <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table sx={{ minWidth: 900 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Broker</TableCell>
                      <TableCell>Client Transaction ID</TableCell>
                      <TableCell>System Transaction ID</TableCell>
                      <TableCell>Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transactions.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          No records to display
                        </TableCell>
                      </TableRow>
                    ) : (
                      transactions
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((transaction) => (
                          <TableRow
                            key={transaction.id}
                            hover
                            onClick={() => handleRowClick(transaction)}
                          >
                            <TableCell>{transaction.merchantName}</TableCell>
                            <TableCell>{transaction.clientTransactionId}</TableCell>
                            <TableCell>{transaction.systemTransactionId}</TableCell>
                            <TableCell>{moment(transaction.dateCreated).tz(userTimeZone).format("DD/MMM/YYYY HH:mm:ss")}</TableCell>
                          </TableRow>
                        ))
                    )}
                  </TableBody>
                </Table>

                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  labelRowsPerPage="Rows per page"
                  count={transactions.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>

              <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                <DialogContent sx={{
                  color: "#e0e0e0",
                  backgroundColor: "#2d2f31",
                }}>
                  <pre
                    style={{
                      overflow: "auto",
                      backgroundColor: "#35373b",
                      padding: "10px",
                      color: "#b3e5fc"
                    }}>
                    <Typography>
                      <span>Broker:</span> {parseBroker}
                    </Typography>
                    {JSON.stringify(parsePayload, null, 2)}
                  </pre>
                </DialogContent>
                <DialogActions sx={{
                  backgroundColor: "#2d2f31",
                  justifyContent: "center"
                }}>
                  <Button sx={{
                    backgroundColor: "#ffeb3b",
                    color: "#292a2c",
                    "&:hover": {
                      backgroundColor: "#fdd830"
                    },
                    borderRadius: "20px",
                    padding: "8px 24px"
                  }}
                    onClick={handleCloseDialog}>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Paper>
        </Box>
      </Grid>
    </LocalizationProvider >
  );
}

export default CallbackProviderList;