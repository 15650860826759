import { FormControl, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { GET, MERCHANT, POST, WITHDRAWBROKER, WITHDRAWLISTBROKER } from '../../service/service';
import { SearchButton } from '../../Theme';
import TableData from './TableData';
import dayjs from 'dayjs';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { SearchTextIcon } from '../../untils/icons.js'
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { NumericFormat } from 'react-number-format';

function WithdrawsBroker() {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [merchant, setMerchant] = useState();
    const [merchantsearch, setMerchantsearch] = useState(0);
    const [merchantList, setMerchantList] = useState([]);
    const [withdrawAmount, setWithdrawAmount] = useState('');
    const [startDate, setStartDate] = useState(moment().startOf("month").tz(userTimeZone));
    const [endDate, setEndDate] = useState(moment().endOf("day").tz(userTimeZone));
    const [withdrawlist, setWithdrawlist] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        WithdrawListBroker();
        getMerchant();
    }, []);

    const getMerchant = async () => {
        try {
            const res = await GET(MERCHANT);
            if (res.success) {
                setMerchantList(res.result);
            } else {
                alert(res.message);
            }
        } catch (error) {
            console.error('Error fetching merchants:', error);
            alert("Failed to fetch merchants.");
        }
    };

    const withdrawBroker = async (broker, amount) => {
        setLoading(true);
        try {
            const res = await POST(WITHDRAWBROKER, {
                merchantid: broker,
                amount: amount,
            });
            if (res.success) {
                WithdrawListBroker();
                setWithdrawAmount('');
                alert(`Withdrawal successful: ฿${amount}`);
            } else {
                alert(res.message);
            }
        } catch (error) {
            console.error('Withdrawal error:', error);
            alert('Withdrawal failed. Please try again.');
        }
        setLoading(false);
    };

    const WithdrawListBroker = async () => {
        try {
            const res = await POST(WITHDRAWLISTBROKER, {
                date_start: startDate,
                date_end: endDate,
                merchant_id: merchantsearch
            });
            if (res.success) {
                setPage(0);
                const sortedList = res.result.sort((a, b) =>
                    dayjs(b.date_created).valueOf() - dayjs(a.date_created).valueOf()
                );
                setWithdrawlist(sortedList);
            } else {
                alert(res.message);
            }
        } catch (error) {
            alert("Error fetching withdrawals: " + error);
        }
    };

    const handleWithdraw = async () => {
        if (!merchant) {
            alert('Please select a Merchant');
            return;
        }
        if (!withdrawAmount) {
            alert('Please enter an Amount');
            return;
        }
        setLoading(true);
        try {
            await withdrawBroker(merchant, withdrawAmount);
        } finally {
            setLoading(false);
        }
    };


    const handleChangeStartDate = (startValue) => {
        if (startValue) {
            if (startValue.isAfter(endDate)) {
                setEndDate(startValue.add(1, "second"));
            } else if (startValue.isSame(endDate)) {
                setEndDate(startValue.add(1, "second"));
            }
            setStartDate(startValue);
        }
    };

    const handleChangeEndDate = (endValue) => {
        if (endValue) {
            if (endValue.isBefore(startDate)) {
                setStartDate(endValue.subtract(1, "second"));
            } else if (endValue.isSame(startDate)) {
                setStartDate(endValue.subtract(1, "second"));
            }
            setEndDate(endValue);
        }
    };

    const handleSearch = () => {
        WithdrawListBroker({ startDate, endDate, merchant_name: merchant })
    };

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                        <InputLabel>ร้านค้า</InputLabel>
                        <Select
                            value={merchant}
                            onChange={(e) => setMerchant(e.target.value)}
                            label="ร้านค้า"
                        >
                            {merchantList.map((item) => (
                                <MenuItem key={item.name} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            label="Amount"
                            value={withdrawAmount}
                            onChange={(e) => {
                                const rawValue = e.target.value.replace(/,/g, '');
                                setWithdrawAmount(rawValue);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleWithdraw();
                                }
                            }}
                            startAdornment={<InputAdornment position="start">฿</InputAdornment>}
                            inputComponent={NumericFormat}
                            inputProps={{
                                thousandSeparator: ",",
                                decimalSeparator: ".",
                                allowNegative: false,
                                isNumericString: true,
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <SearchButton
                        fullWidth
                        variant="outlined"
                        onClick={handleWithdraw}
                        disabled={loading}
                    >
                        {loading ? "Processing..." : "Withdraw"}
                    </SearchButton>
                </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="center" spacing={2} sx={{ marginTop: 2 }}>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                        <InputLabel>ร้านค้า</InputLabel>
                        <Select
                            value={merchantsearch}
                            onChange={(e) => setMerchantsearch(e.target.value)}
                            label="ร้านค้า"
                        >
                            <MenuItem value={0}>All</MenuItem>
                            {merchantList.map((item) => (
                                <MenuItem key={item.name} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid item xs={12} sm={2.5}>
                        <DesktopDatePicker
                            label="วันที่เริ่มต้น"
                            inputFormat="DD/MMM/YYYY HH:mm:ss"
                            value={startDate}
                            onChange={handleChangeStartDate}
                            maxDate={dayjs()}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2.5}>
                        <DesktopDatePicker
                            label="วันที่สิ้นสุด"
                            inputFormat="DD/MMM/YYYY HH:mm:ss"
                            value={endDate}
                            onChange={handleChangeEndDate}
                            minDate={startDate}
                            maxDate={dayjs()}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </Grid>
                </LocalizationProvider>
                <Grid item xs={12} sm={1} md={2} sx={{ mt: 1 }}>
                    <SearchButton width="auto" variant="outlined" onClick={handleSearch} startIcon={<SearchTextIcon />}>ค้นหา</SearchButton>
                </Grid>
            </Grid>
            <TableData
                withdrawlist={withdrawlist}
                startDate={startDate}
                endDate={endDate}
                broker={merchant}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
        </>
    );
}

export default WithdrawsBroker;