import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import React from 'react'
import { thousandsSeparator } from '../../../untils/shortcut'
import moment from 'moment-timezone'

const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function ReportWithdrawMobile({ transactions, page, rowsPerPage, handleRefund, getStatusColor }) {
    return (
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table>
                <TableBody>
                    {transactions.length === 0 ? (
                        <TableRow>
                            <TableCell
                                colSpan={6}
                                align="center"
                            >
                                No records to display
                            </TableCell>
                        </TableRow>
                    ) : (
                        transactions
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((transaction) => (
                                <TableRow key={transaction.id}>
                                    <TableCell>
                                        <div className="transaction-card">
                                            <p><strong>Client Transaction ID:</strong> {transaction.clientTransactionId}</p>
                                            <p><strong>System Transaction ID:</strong> {transaction.systemTransactionId}</p>
                                            <p><strong>Merchant Name:</strong> {transaction.merchant.name}</p>
                                            <p><strong>Amount:</strong> {thousandsSeparator(transaction.amount)}</p>
                                            <p><strong>amountWithFee:</strong> {thousandsSeparator(transaction.amountWithFee)}</p>
                                            <p><strong>Status:</strong> <span style={{ color: getStatusColor(transaction.status) ,fontWeight: 'bold' }}>{transaction.status}</span></p>
                                            <p>Date Created: {moment(transaction.dateCreated).tz(userTimeZone).format("DD/MMM/YYYY HH:mm:ss")}</p>
                                            <p>Date Updated: {moment(transaction.dateUpdated).tz(userTimeZone).format("DD/MMM/YYYY HH:mm:ss")}</p>
                                            {transaction.status === 'failed' && (
                                                transaction.refund !== 1 ? (
                                                    <Button
                                                        variant="outlined"
                                                        color="success"
                                                        onClick={() => handleRefund(transaction.systemTransactionId)}
                                                    >
                                                        Refund
                                                    </Button>
                                                ) : (
                                                    <span style={{ color: 'green', textAlign: 'center' , fontWeight: 'bold'}}>Refund Success</span>
                                                )
                                            )}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
};

export default ReportWithdrawMobile