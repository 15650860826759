import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Box,
    FormControl,
    Grid,
    MenuItem,
    Paper,
    Select,
    TextField,
    InputLabel,
    useMediaQuery,
} from '@mui/material';

//theme
import { SaveButton } from '../../Theme';

//components
import PageHeader from '../common/PageHeader';

//service
import { POST, UPDATEUSER, GETUSERBYID } from '../../service/service';

//alert
import Swal from 'sweetalert2'

//icon
import { EditDataIcon } from '../../untils/icons';

function UserEdit() {

    const params = useParams();

    const [username, setUsername] = useState();
    const [role, setRole] = useState();
    const [name, setName] = useState();
    const [lastname, setLastname] = useState();

    const [userData, setUserData] = useState([]);

    const getUserByID = async () => {

        try {
            let res = await POST(GETUSERBYID, { user_id: params.user_id });
            if (res.success) {
                // (res.result);
                setUserData(res.result);
            } else {
                alert(res.message);
            }

        } catch (error) {
            alert(error);
        }
    }

    const updateUser = async () => {
        try {
            let res = await POST(UPDATEUSER, {
                username: username ? username : userData[0].username
                , role: role ? role : userData[0].role
                , name: name ? name : userData[0].name
                , lastname: lastname ? lastname : userData[0].lastname
                , user_id: params.user_id
            });
            if (res.success) {
                Swal.fire({
                    icon: 'success',
                    text: `${res.result.message}`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace("/User");
                    }
                })
            } else {
                Swal.fire({
                    icon: 'warning',
                    text: `${res.message}`,
                    confirmButtonText: 'ตกลง',
                })
            }
        } catch (error) {
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }
    };

    const matchMobile = useMediaQuery((theme) => theme.breakpoints.only("xs"));

    const matchIpad = useMediaQuery((theme) => theme.breakpoints.only("sm"));

    useEffect(() => {
        getUserByID();
    }, []);


    return (

        <Grid >
            <PageHeader title="แก้ไขรายการพนักงาน" buttonBack={true} />
            <>
                {((matchMobile === false) && (matchIpad === false)) && (
                    <Box display="flex" justifyContent="center" p={5}>
                        {userData.map((i) => (
                            <Paper sx={{ width: '80%', p: 3 }} elevation={0}>
                                <Grid container direction="row" spacing={4}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField id="outlined-basic" label="ชื่อผู้ใช้งาน" defaultValue={i.username} value={username} onChange={(e) => setUsername(e.currentTarget.value)} variant="outlined" placeholder='ชื่อผู้ใช้งาน' required fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-autowidth-label">บทบาท</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-autowidth-label"
                                                id="demo-simple-select-autowidth"
                                                defaultValue={i.role}
                                                value={role}
                                                onChange={(event) => setRole(event.target.value)}
                                                autoWidth
                                                label="บทบาท"
                                            >
                                                <MenuItem value="admin">Admin</MenuItem>
                                                <MenuItem value="co-admin">Co-Admin</MenuItem>
                                                <MenuItem value="employee">Employee</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField id="outlined-basic" label="ชื่อ" defaultValue={i.name} value={name} onChange={(e) => setName(e.currentTarget.value)} variant="outlined" placeholder='นายพิชัย' required fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField id="outlined-basic" label="นามสกุล" defaultValue={i.lastname} value={lastname} onChange={(e) => setLastname(e.currentTarget.value)} variant="outlined" placeholder='อาจหาญ' required fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Box
                                            sx={{
                                                '& > :not(style)': { mt: 1, mb: 3, height: '6ch' },
                                            }}
                                            className='saveButton'
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            <SaveButton fullWidth variant="outlined" onClick={() => updateUser()} startIcon={<EditDataIcon />}>แก้ไข</SaveButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                        ))}
                    </Box>
                )}
            </>
            <>
                {((matchMobile === true) && (matchIpad === false)) && (
                    <Box display="flex" justifyContent="center" p={5}>
                        {userData.map((i) => (
                            <Paper sx={{ width: '100%' }} elevation={0}>
                                <Grid container direction="row" spacing={4}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField id="outlined-basic" label="ชื่อผู้ใช้งาน" defaultValue={i.username} value={username} onChange={(e) => setUsername(e.currentTarget.value)} variant="outlined" placeholder='ชื่อผู้ใช้งาน' required fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-autowidth-label">บทบาท</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-autowidth-label"
                                                id="demo-simple-select-autowidth"
                                                defaultValue={i.role}
                                                value={role}
                                                onChange={(event) => setRole(event.target.value)}
                                                autoWidth
                                                label="บทบาท"
                                            >
                                                <MenuItem value="admin">Admin</MenuItem>
                                                <MenuItem value="co-admin">Co-Admin</MenuItem>
                                                <MenuItem value="employee">Employee</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField id="outlined-basic" label="ชื่อ" defaultValue={i.name} value={name} onChange={(e) => setName(e.currentTarget.value)} variant="outlined" placeholder='นายพิชัย' required fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField id="outlined-basic" label="นามสกุล" defaultValue={i.lastname} value={lastname} onChange={(e) => setLastname(e.currentTarget.value)} variant="outlined" placeholder='อาจหาญ' required fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Box
                                            sx={{
                                                '& > :not(style)': { mt: 1, mb: 3, height: '6ch' },
                                            }}
                                            className='saveButton'
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            <SaveButton fullWidth variant="outlined" onClick={() => updateUser()} startIcon={<EditDataIcon />}>แก้ไข</SaveButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                        ))}
                    </Box>
                )}
            </>

            <>
                {((matchMobile === false) && (matchIpad === true)) && (
                    <Box display="flex" justifyContent="center" p={5}>
                        {userData.map((i) => (
                            <Paper sx={{ width: '80%', p: 2 }} elevation={0}>
                                <Grid container direction="row" spacing={4}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField id="outlined-basic" label="ชื่อผู้ใช้งาน" defaultValue={i.username} value={username} onChange={(e) => setUsername(e.currentTarget.value)} variant="outlined" placeholder='ชื่อผู้ใช้งาน' required fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-autowidth-label">บทบาท</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-autowidth-label"
                                                id="demo-simple-select-autowidth"
                                                defaultValue={i.role}
                                                value={role}
                                                onChange={(event) => setRole(event.target.value)}
                                                autoWidth
                                                label="บทบาท"
                                            >
                                                <MenuItem value="admin">Admin</MenuItem>
                                                <MenuItem value="co-admin">Co-Admin</MenuItem>
                                                <MenuItem value="employee">Employee</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField id="outlined-basic" label="ชื่อ" defaultValue={i.name} value={name} onChange={(e) => setName(e.currentTarget.value)} variant="outlined" placeholder='นายพิชัย' required fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField id="outlined-basic" label="นามสกุล" defaultValue={i.lastname} value={lastname} onChange={(e) => setLastname(e.currentTarget.value)} variant="outlined" placeholder='อาจหาญ' required fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Box
                                            sx={{
                                                '& > :not(style)': { mt: 1, mb: 3, height: '6ch' },
                                            }}
                                            className='saveButton'
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            <SaveButton fullWidth variant="outlined" onClick={() => updateUser()} startIcon={<EditDataIcon />}>แก้ไข</SaveButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                        ))}
                    </Box>
                )}
            </>
        </Grid>
    )
}

export default UserEdit;