import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { thousandsSeparator } from '../../../untils/shortcut'
import moment from 'moment-timezone'

const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function ReportWithdrawDesktop({ transactions, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, handleRefund, getStatusColor }) {
    return (
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Client Transaction Id</TableCell>
                        <TableCell>System Transaction Id</TableCell>
                        <TableCell>Merchant Name</TableCell>
                        <TableCell>Id</TableCell>
                        <TableCell>Balance Before</TableCell>
                        <TableCell>Balance After</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Amount_With_Fee</TableCell>
                        <TableCell>Error</TableCell>
                        <TableCell>Ref1</TableCell>
                        <TableCell>Bank Name</TableCell>
                        <TableCell>Book BankNumber</TableCell>
                        <TableCell>Book Bank_Name</TableCell>
                        <TableCell>Book Cank_Branch</TableCell>
                        <TableCell>Date Created</TableCell>
                        <TableCell>Date Updated</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactions.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={18} align="center">
                                No records to display
                            </TableCell>
                        </TableRow>
                    ) : (
                        transactions
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((transaction) => (
                                <TableRow key={transaction.id}>
                                    <TableCell>
                                        {transaction.status === 'failed' && (
                                            transaction.refund !== 1 ? (
                                                <Button
                                                    variant="outlined"
                                                    color="success"
                                                    onClick={() => handleRefund(transaction.systemTransactionId)}
                                                >
                                                    Refund
                                                </Button>
                                            ) : (
                                                <span style={{ color: 'green', textAlign: 'center', fontWeight: 'bold' }}>Refund Success</span>
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            color: getStatusColor(transaction.status),
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {transaction.status}
                                    </TableCell>
                                    <TableCell>{transaction.clientTransactionId}</TableCell>
                                    <TableCell>{transaction.systemTransactionId}</TableCell>
                                    <TableCell>{transaction.merchant.name}</TableCell>
                                    <TableCell>{transaction.id}</TableCell>
                                    <TableCell>{thousandsSeparator(transaction.balanceBefore)}</TableCell>
                                    <TableCell>{thousandsSeparator(transaction.balanceAfter)}</TableCell>
                                    <TableCell>{thousandsSeparator(transaction.amount)}</TableCell>
                                    <TableCell>{thousandsSeparator(transaction.amountWithFee)}</TableCell>
                                    <TableCell>{transaction.error}</TableCell>
                                    <TableCell>{transaction.ref1}</TableCell>
                                    <TableCell>{transaction.bankName}</TableCell>
                                    <TableCell>{transaction.bookBankNumber}</TableCell>
                                    <TableCell>{transaction.bookBankName}</TableCell>
                                    <TableCell>{transaction.bookBankBranch}</TableCell>
                                    <TableCell>{moment(transaction.dateCreated).tz(userTimeZone).format("DD/MMM/YYYY HH:mm:ss")}</TableCell>
                                    <TableCell>{moment(transaction.dateUpdated).tz(userTimeZone).format("DD/MMM/YYYY HH:mm:ss")}</TableCell>
                                </TableRow>
                            ))
                    )}
                </TableBody>
            </Table>

            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                labelRowsPerPage="Rows per page"
                count={transactions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
    )
}

export default ReportWithdrawDesktop