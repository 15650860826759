import React, { useState, useEffect } from 'react'
import CustomDrawer from './CustomDrawer';
import CustomDrawerCoAdmin from './CustomDrawerCoAdmin';
import CustomDrawerEmployee from './CustomDrawerEmployee';

function LayoutMain({ children }) {

  const [path_now, setPath_now] = useState();
  const [path, setPath] = useState("");

  useEffect(() => {
    setPath("/");
    const path = window.location.pathname;
    const strs = path.split("/");
    setPath_now(strs[1]);
  }, [path]);

  return (
    <>
      {/* {localStorage.getItem("role") === "admin" &&
        (path_now !== "addTransaction" ?
          <CustomDrawer children={children} /> :
          window.location.replace("/")
        )
      }

      {localStorage.getItem("role") === "employee" &&
        ((path_now !== "Record" && path_now !== "Borrow" && path_now !== "Car" && path_now !== "Customer" && path_now !== "Summary") ?
          <CustomDrawerEmployee children={children} /> :
          window.location.replace("/")
        )
      } */}

      {localStorage.getItem("role") === "admin" && <CustomDrawer children={children} />}
      {localStorage.getItem("role") === "co-admin" && <CustomDrawerCoAdmin children={children} />}
      {localStorage.getItem("role") === "employee" && <CustomDrawerEmployee children={children} />}


    </>
  );
}

export default LayoutMain;
