import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from "@mui/material";
import dayjs from "dayjs";
import { thousandsSeparator } from "../../../untils/shortcut";
import moment from "moment-timezone";

const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const ReportDepositDesktop = ({ transactions, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, getStatusColor }) => (
    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>Client Transaction Id</TableCell>
                    <TableCell>System Transaction Id</TableCell>
                    <TableCell>Merchant Name</TableCell>
                    <TableCell>Id</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Balance Before</TableCell>
                    <TableCell>Balance After</TableCell>
                    <TableCell>Amount With Fee</TableCell>
                    <TableCell>Date Created</TableCell>
                    <TableCell>Date Updated</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {transactions.length === 0 ? (
                    <TableRow>
                        <TableCell colSpan={11} align="center">
                            No records to display
                        </TableCell>
                    </TableRow>
                ) : (
                    transactions
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((transaction) => (
                            <TableRow key={transaction.id}>
                                <TableCell style={{ color: getStatusColor(transaction.status), fontWeight: "bold" }}>
                                    {transaction.status}
                                </TableCell>
                                <TableCell>{transaction.clientTransactionId}</TableCell>
                                <TableCell>{transaction.systemTransactionId}</TableCell>
                                <TableCell>{transaction.merchant.name}</TableCell>
                                <TableCell>{transaction.id}</TableCell>
                                <TableCell>{thousandsSeparator(transaction.amount)}</TableCell>
                                <TableCell>{thousandsSeparator(transaction.balanceBefore)}</TableCell>
                                <TableCell>{thousandsSeparator(transaction.balanceAfter)}</TableCell>
                                <TableCell>{thousandsSeparator(transaction.amountWithFee)}</TableCell>
                                <TableCell>{moment(transaction.dateCreated).tz(userTimeZone).format("DD/MMM/YYYY HH:mm:ss")}</TableCell>
                                <TableCell>{moment(transaction.dateUpdated).tz(userTimeZone).format("DD/MMM/YYYY HH:mm:ss")}</TableCell>
                            </TableRow>
                        ))
                )}
            </TableBody>
        </Table>

        <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            labelRowsPerPage="Rows per page"
            count={transactions.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </TableContainer>
);

export default ReportDepositDesktop;