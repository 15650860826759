import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

//Record Ice Transaction
import ListTransactionMerchant from "../pages/ListTransactionMerchant";

//Car
import Car from "../pages/Merchant";
import CarRecord from "./merchant/MerchantRecord";
import MerchantEditRecord from "./merchant/MerchantEditRecord";

//Summary
import Summary from "../pages/Summary";
import ListTodayData from "./summary/ListTodayData";

//Login
import Login from "../pages/Login";

//User
import User from "../pages/User";
import UserRecord from "./user/UserRecord";
import UserEdit from "./user/UserEdit";
import UserEditPassword from "./user/UserEditPassword";

import Settlement from "../pages/Settlement";

//ResetPassword
import ResetPassword from "../pages/ResetPassword";

//Agetn
import Agent from "../pages/Agent";
import AgentRecord from "./agent/AgentRecord";

import TransactionV2 from "../pages/TransactionV2";

//Callback
import CallbackMerchant from "../pages/CallbackMerchant";
import CallbackProvider from "../pages/CallbackProvider";

//Report
import ReportWithdrawLog from "../pages/ReportWithdraw";
import ReportDepositLog from "../pages/ReportDeposit";

//WithdrawBroker
import WithdrawBrokerPage from "../pages/WithdrawBrokerPage";

function RouterMain() {
  return (

    <Routes>
      <Route path="/">
        {/* Login */}
        <Route path="Login" element={<Login />} />

        {/* Record owner */}
        <Route index element={<ListTransactionMerchant />} />

        {/* Merchant */}
        <Route path="Merchant" element={localStorage.getItem("role") === "employee" ? <Navigate to="/" /> : <Car />} />
        <Route path="Merchant/merchantRecord" element={localStorage.getItem("role") === "employee" ? <Navigate to="/" /> : <CarRecord />} />
        <Route path="Merchant/edit/:merchant_id" element={localStorage.getItem("role") === "employee" ? <Navigate to="/" /> : <MerchantEditRecord />} />



        {/* Settlement */}
        <Route path="Settlement" element={localStorage.getItem("role") === "employee" ? <Navigate to="/" /> : <Settlement />} />

        {/* Summary */}
        <Route path="Summary" element={localStorage.getItem("role") === "admin" ? <Summary /> : <Navigate to="/" />} />
        <Route path="Summary/Detail/:car_id/:startDate/:endDate" element={localStorage.getItem("role") === "admin" ? <ListTodayData /> : <Navigate to="/" />} />

        {/* User */}
        <Route path="User" element={localStorage.getItem("role") === "admin" ? <User /> : <Navigate to="/" />} />
        <Route path="User/UserRecord" element={localStorage.getItem("role") === "admin" ? <UserRecord /> : <Navigate to="/" />} />
        <Route path="User/UserEdit/:user_id" element={localStorage.getItem("role") === "admin" ? <UserEdit /> : <Navigate to="/" />} />
        <Route path="User/UserEditPassword/:user_id" element={localStorage.getItem("role") === "admin" ? <UserEditPassword /> : <Navigate to="/" />} />

        {/* Agent */}
        <Route path="Agent" element={localStorage.getItem("role") === "admin" ? <Agent /> : <Navigate to="/" />} />
        <Route path="Agent/AgentRecord" element={localStorage.getItem("role") === "admin" ? <AgentRecord /> : <Navigate to="/" />} />

        {/* TransactionV2 */}
        <Route path="Transaction" element={localStorage.getItem("role") === "admin" ? <TransactionV2 /> : <Navigate to="/" />} />

        {/* Callback */}
        <Route path="CallbackProvider" element={localStorage.getItem("role") === "admin" ? <CallbackProvider /> : <Navigate to="/" />} />
        <Route path="CallbackMerchant" element={localStorage.getItem("role") === "admin" ? <CallbackMerchant /> : <Navigate to="/" />} />

        {/* ReportLog */}
        <Route path="ReportWithdraw" element={localStorage.getItem("role") === "admin" ? <ReportWithdrawLog /> : <Navigate to="/" />} />
        <Route path="ReportDeposit" element={localStorage.getItem("role") === "admin" ? <ReportDepositLog /> : <Navigate to="/" />} />

        {/* WithdrawBroker */}
        <Route path="WithdrawBroker" element={localStorage.getItem("role") === "admin" ? <WithdrawBrokerPage /> : <Navigate to="/" />} />

        {/* Reset Password */}
        <Route path="ResetPassword" element={<ResetPassword />} />

      </Route>
    </Routes>
  );
}

export default RouterMain;
