import * as XLSX from "xlsx";
import React, { useState, useEffect, useMemo } from 'react'
import {
    Box,
    Grid,
    Paper,
    Stack,
    Typography,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    useMediaQuery,
    TablePagination,
    TableRow,
    Divider,
    Chip,
    Link,
    Button,
    IconButton,
} from '@mui/material';
import { MaterialReactTable } from 'material-react-table';

//calendar
import moment from "moment-timezone";
import { ImportExport } from '@mui/icons-material';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

//theme
import {
    SearchButton,
    StyledTableCell,
    StyledTableRow,
    commonStylesIceList
} from '../../Theme.jsx';
import { useNavigate } from "react-router-dom";

//service
import { GET, POST, TRANSACTION, MERCHANT } from '../../service/service.js';

//untils
import { shortcutTime, thousandsSeparator } from '../../untils/shortcut.js'

//icons
import { SearchTextIcon, LinkIcon } from '../../untils/icons.js'
import dayjs from "dayjs";

const TransactionList = () => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [iceTransectionData, setIceTransectionData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const [startDate, setStartDate] = useState(moment().startOf('month').tz(userTimeZone));
    const [endDate, setEndDate] = useState(moment().endOf('day').tz(userTimeZone));
    const [type, setType] = useState('0');
    const [merchantList, setMerchantList] = useState([])
    const [merchant, setMerchant] = useState('0')
    const [text, setText] = useState('');

    const [showMoreOut, setShowMoreout] = useState(false);
    const [showMoreIn, setShowMoreIn] = useState(false);

    const [depositFee, setDepositFee] = useState(0);
    const [withdrawFee, setWithdrawFee] = useState(0);
    const [depositAmount, setDepositAmount] = useState(0);
    const [withdrawAmount, setWithdrawAmount] = useState(0);

    const [state, setState] = useState(false);
    const navigate = useNavigate();

    const columns = useMemo(
        () => [
            // {
            //     accessorKey: 'car_part_id',
            //     header: 'ID',
            //     enableColumnOrdering: false,
            //     enableEditing: false, //disable editing on this column
            //     enableSorting: false,
            //     disabled: true,
            //     size: 80,
            // },
            {
                accessorKey: 'transactions_date_created',
                header: 'Time',
                size: 200,
                accessorFn: (e) => moment(e.date_created).tz(userTimeZone).format('DD/MMM/YYYY HH:mm:ss'),
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'client_transaction_id',
                header: 'Client Transaction Id',
                size: 50,
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'system_transaction_id',
                header: 'System Transaction Id',
                size: 50,
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'merchants_name',
                header: 'Merchant Name',
                size: 50,
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'tx_type',
                header: 'Type',
                size: 50,
                Cell: row => <div style={{ color: row.renderedCellValue === 'deposit' ? 'green' : 'red' }}>{row.renderedCellValue}</div>
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'amount',
                header: 'Amount',
                inputType: 'number',
                size: 50,
                accessorFn: (e) => thousandsSeparator(e.amount),
                Cell: row => <div style={{ textAlign: "right" }}>{row.renderedCellValue}</div>
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'fee_amount',
                header: 'Fee',
                inputType: 'number',
                size: 50,
                accessorFn: (e) => thousandsSeparator(e.fee_amount),
                Cell: row => <div style={{ textAlign: "right" }}>{row.renderedCellValue}</div>
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                header: 'Agent Fee',
                inputType: 'number',
                size: 50,
                accessorFn: (e) => {
                    const fee =
                        e.tx_type === "deposit"
                            ? e.agent_fee_in
                                ? e.agent_fee_in * e.amount / 100
                                : 0
                            : e.agent_fee_out
                                ? e.agent_fee_out * e.amount / 100
                                : 0;
                    return thousandsSeparator(fee);
                },

                Cell: row => <div style={{ textAlign: "right" }}>{row.renderedCellValue}</div>
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
        ],
        [],
    );

    const handleChangeStartDate = (startValue) => {
        if (startValue) {
            if (startValue.isAfter(endDate)) {
                setEndDate(startValue.add(1, "second"));
            } else if (startValue.isSame(endDate)) {
                setEndDate(startValue.add(1, "second"));
            }
            setStartDate(startValue);
        }
    };

    const handleChangeEndDate = (endValue) => {
        if (endValue) {
            if (endValue.isBefore(startDate)) {
                setStartDate(endValue.subtract(1, "second"));
            } else if (endValue.isSame(startDate)) {
                setStartDate(endValue.subtract(1, "second"));
            }
            setEndDate(endValue);
        }
    };

    // const getUser = async () => {

    //     try {
    //         let res = await GET(GETAllUSER);
    //         if (res.success) {
    //             setUserData(res.result);
    //         } else {
    //             alert(res.message);
    //         }

    //     } catch (error) {
    //         alert(error);
    //     }
    // }

    const exportTransaction = () => {
        if (!iceTransectionData || iceTransectionData.length === 0) {
            alert("No data available to export");
            return;
        }

        const data = iceTransectionData.map(e => {
            return {
                "Merchants Name": e.merchants_name,
                "ClientID": e.client_transaction_id,
                "SystemID": e.system_transaction_id,
                "Amount": thousandsSeparator(e.amount),
                "Amount With Fee": thousandsSeparator(e.amount_with_fee),
                "Sum Fee": thousandsSeparator(e.fee_amount),
                "Total Fee %": thousandsSeparator(e.total_fee),
                "Agent Fee":
                    e.tx_type === "deposit"
                        ? thousandsSeparator(e.agent_fee_in)
                            ? thousandsSeparator((e.agent_fee_in * e.amount) / 100)
                            : "0.00"
                        : thousandsSeparator(e.agent_fee_out)
                            ? thousandsSeparator((e.agent_fee_out * e.amount) / 100)
                            : "0.00",
                "Type": e.tx_type,
            };
        });

        const worksheet = XLSX.utils.json_to_sheet(data);

        const columnWidths = {};
        for (let i = 0; i < data.length; i++) {
            Object.keys(data[i]).forEach((key, colIndex) => {
                const cell = worksheet[XLSX.utils.encode_cell({ r: i + 1, c: colIndex })];
                if (cell && cell.v) {
                    const cellValue = cell.v.toString();
                    const currentMaxLength = columnWidths[key] || 0;
                    columnWidths[key] = Math.max(currentMaxLength, cellValue.length);
                }
            });
        }

        worksheet["!cols"] = Object.keys(columnWidths).map((key) => ({
            wpx: columnWidths[key] * 10,
        }));

        const range = XLSX.utils.decode_range(worksheet["!ref"]);
        for (let col = range.s.c; col <= range.e.c; col++) {
            const header = XLSX.utils.encode_col(col) + "1";
            const headerValue = worksheet[header]?.v;

            if (
                ["amount", "amount_with_fee", "fee_amount", "total_fee", "agent_fee_in", "agent_fee_out"].includes(
                    headerValue
                )
            ) {
                for (let row = 2; row <= range.e.r + 1; row++) {
                    const cell = XLSX.utils.encode_cell({ r: row - 1, c: col });
                    if (worksheet[cell]) {
                        worksheet[cell].t = "n";
                        worksheet[cell].z = "#,##0.00";
                    }
                }
            }
        }

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Deposits");

        const fileName = `Report TransactionList ${localStorage.getItem("name")} ${(startDate).format(
            "DD-MMM-YYYY")} - ${(endDate).format("DD-MMM-YYYY")}.xlsx`;

        XLSX.writeFile(workbook, fileName);
    };

    const searchTransaction = async () => {
        try {
            let res = await POST(TRANSACTION, {
                date_start: dayjs(startDate),
                date_end: dayjs(endDate),
                type: type,
                merchant_id: merchant.toString()
            });
            if (res.success) {
                const sortedData = res.result.sort((a, b) =>
                    new Date(b.date_created) - new Date(a.date_created)
                );

                setIceTransectionData(sortedData);

                let sumDeposit = 0;
                let sumWithdraw = 0;
                let sumDepositFee = 0;
                let sumWithdrawFee = 0;

                res.result.forEach(e => {
                    if (e.tx_type === 'deposit') {
                        sumDeposit += Number(e.amount);
                        sumDepositFee += Number(e.fee_amount);
                    } else {
                        sumWithdraw += Number(e.amount);
                        sumWithdrawFee += Number(e.fee_amount);
                    }
                });

                setDepositAmount(sumDeposit);
                setWithdrawAmount(sumWithdraw);
                setWithdrawFee(sumWithdrawFee);
                setDepositFee(sumDepositFee);

            } else {
                if (res.statusCode === 401) {
                    localStorage.removeItem('token_Login');
                    localStorage.removeItem('username');
                    window.location.replace("/");
                } else {
                    alert(res.message);
                }
            }
        } catch (error) {
            console.error("Error occurred during transaction processing:", error);
            alert("An error occurred. Please try again.");
        }
    }

    const getMerchant = async () => {
        try {
            let res = await GET(MERCHANT);
            if (res.success) {
                setMerchantList(res.result);
            } else {
                if (res.statusCode === 401) {
                    localStorage.removeItem('token_Login');
                    localStorage.removeItem('username');
                    window.location.replace("/");
                } else {
                    alert(res.message);
                }

            }
        } catch (error) {
            alert("error", error);
        }
    };

    useEffect(() => {
        searchTransaction();
        getMerchant();
        // getUser();
    }, []);


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid>
                <Box display="flex" justifyContent="center">
                    <Paper sx={{ width: '90%', my: 1 }} elevation={0}>
                        <Typography variant="caption" color="#2996D1" fontSize={18} display="flex" fontWeight="bold" alignItesm="center" sx={{ pt: 2, px: 1.5 }}>
                            ค้นหา
                        </Typography>
                        <Grid item xs={12} sx={{ p: 1.5 }}>
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={12} sm={2.5} md={2.5}>
                                    <Grid container direction="row" spacing={1}>
                                        <Grid item xs={12} align="center" pt={1}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">ประเภท</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    value={type}
                                                    label="ประเภท"
                                                    onChange={(e) => setType(e.target.value)}
                                                >
                                                    <MenuItem value={'0'}>ทั้งหมด</MenuItem>
                                                    <MenuItem value={'1'}>Deposit</MenuItem>
                                                    <MenuItem value={'2'}>Withdraw</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={2.5} md={2.5}>
                                    <Grid container direction="row" spacing={1}>
                                        <Grid item xs={12} align="center" pt={1}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">ร้านค้า</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    value={merchant}
                                                    label="ร้านค้า"
                                                    onChange={(e) => setMerchant(e.target.value)}
                                                >
                                                    <MenuItem value={'0'}>ทั้งหมด</MenuItem>
                                                    {merchantList.map(e => {
                                                        return <MenuItem value={e.id}>{e.name}</MenuItem>
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={2.5} md={2.5}>
                                    <Grid container direction="row" spacing={1}>
                                        <Grid item xs={12} align="center" pt={1}>
                                            <DesktopDatePicker
                                                label="วันที่เริ่มต้น"
                                                inputFormat="DD/MMM/YYYY HH:mm:ss"
                                                value={startDate}
                                                onChange={handleChangeStartDate}
                                                maxDate={dayjs()}
                                                renderInput={(params) => <TextField {...params} fullWidth />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={2.5} md={2.5}>
                                    <Grid container direction="row" spacing={1}>
                                        <Grid item xs={12} align="center" pt={1}>
                                            <DesktopDatePicker
                                                label="วันที่สิ้นสุด"
                                                inputFormat="DD/MMM/YYYY HH:mm:ss"
                                                value={endDate}
                                                onChange={handleChangeEndDate}
                                                minDate={startDate}
                                                maxDate={dayjs()}
                                                renderInput={(params) => <TextField {...params} fullWidth />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={1} md={1} sx={{ mt: 1 }}>
                                    <SearchButton width="auto" variant="outlined" onClick={searchTransaction} startIcon={<SearchTextIcon />}>ค้นหา</SearchButton>
                                </Grid>
                                <Grid item xs={12} sm={1} md={1} sx={{ mt: 1 }}>
                                    <SearchButton width="auto" variant="outlined" onClick={exportTransaction} startIcon={<ImportExport />}>Export</SearchButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ p: 1.5 }}>
                            <Grid item xs={12} sm={12} md={3}>
                                <Typography>ยอดฝาก {thousandsSeparator(depositAmount, 2)} บาท</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <Typography>ยอดถอน {thousandsSeparator(withdrawAmount, 2)} บาท</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <Typography>ค่าธรรมเนียมฝาก {thousandsSeparator(depositFee, 2)} บาท</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <Typography>ค่าธรรมเนียมถอน {thousandsSeparator(withdrawFee, 2)} บาท</Typography>
                            </Grid>
                        </Grid>

                        <MaterialReactTable
                            displayColumnDefOptions={{
                                'mrt-row-actions': {
                                    muiTableHeadCellProps: {
                                        align: 'center',
                                    },
                                    size: 120,
                                },
                            }}
                            columns={columns}
                            data={iceTransectionData}
                            enableColumnOrdering
                            enableEditing={false}
                            enableRowNumbers
                            rowNumberMode="original"
                        // onEditingRowSave={handleSaveRowEdits}
                        // onEditingRowCancel={handleCancelRowEdits} 
                        />
                    </Paper>
                </Box>
            </Grid>
        </LocalizationProvider>
    )
}

export default TransactionList;
