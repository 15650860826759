import React from "react";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

//theme
import { HeaderButton } from "../../Theme.jsx"
import ButtonBack from "./ButtonBack.jsx";

function PageHeader({ title, TextButton, path, buttonBack = { Boolean } }) {

  const navigate = useNavigate();

  const handleRoute = (path) => {
    return () => {
      navigate(path);
    };
  };


  return (
    <>
      {path ?
        <Box className="HeaderBanner" sx={{ mt: 2 }}>
          <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
            <Grid item xs={8}>
              <Stack direction="row">
                <Grid sx={{ mt: 1.5, px: 1, py: 1 }} display="flex" alignItems="center">
                  {(buttonBack === true || buttonBack === "true") && <ButtonBack />}
                </Grid>
                <Grid sx={{ mt: 1.5, px: 1, py: 1 }} display="flex" alignItems="center">
                  <Typography className="text-title-header" sx={{ py: 1, px: 2 }}>
                    {title}
                  </Typography>
                </Grid>
              </Stack>
            </Grid>
            {TextButton ?
              <Grid item xs={3.5} sx={{ mt: 1 }} display="flex" alignItems="center" justifyContent="flex-end">
                <HeaderButton onClick={handleRoute(`/${path}`)}>{TextButton}</HeaderButton>
              </Grid>
              : null}
          </Grid>
        </Box >
        :
        <Box className="HeaderBanner" sx={{ mt: 2 }}>
          <Grid container columnSpacing={{ xs: 2, sm: 1, md: 1 }}>
            <Grid item xs={12} sx={{ mt: 1.5, mx: 2, py: 1 }} display="flex" justifyContent="flex-start" alignItems="center">
              {(buttonBack === true || buttonBack === "true") && <ButtonBack />}
              <Typography className="text-title-header" sx={{ py: 1, px: 2 }}>
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Box >
      }
    </>
  );
}

export default PageHeader;
