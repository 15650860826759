import React, { useState, useEffect, useMemo } from 'react'
import {
    Box,
    Paper,
    IconButton
} from "@mui/material";

import { MaterialReactTable } from 'material-react-table';

import { useNavigate } from "react-router-dom";

//service
import { GET, POST, DELETECAR, SEARCHCAR, MERCHANT } from '../../service/service';
import ModeIcon from '@mui/icons-material/Mode';


//alert
import Swal from 'sweetalert2'
import { thousandsSeparator } from '../../untils/shortcut';

const CarTable = () => {

    const [data, setData] = useState([]);
    const [text, setText] = useState('');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const navigate = useNavigate();

    const handleRoute = (path) => {
        navigate(path);
    };

    const getMerchant = async () => {
        try {
            let res = await GET(MERCHANT);
            if (res.success) {
                setData(res.result);
            } else {
                if (res.statusCode === 401) {
                    localStorage.removeItem('token_Login');
                    localStorage.removeItem('username');
                    window.location.replace("/");
                } else {
                    alert(res.message);
                }
            }
        } catch (error) {
            alert("error", error);
        }
    };

    const deleteCar = async (car_id) => {
        try {
            let res = await POST(DELETECAR, { status: false, car_id });
            if (res.success) {
                Swal.fire({
                    icon: 'success',
                    text: `${res.result.message}`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            } else {
                Swal.fire({
                    icon: 'warning',
                    text: `${res.message}`,
                    confirmButtonText: 'ตกลง',
                })
            }
        } catch (error) {
            alert("error", error);
        }
    };

    const deleteAlert = (car_id) => {
        Swal.fire({
            title: 'คุณต้องการลบหรือไม่?',
            text: "ถ้าลบแล้วคุณไม่สามารถกู้คืนรายชื่อพนักงานขับรถได้",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ลบ',
            confirmButtonColor: "#d33",
            cancelButtonText: 'ไม่ลบ',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCar(car_id);
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire({
                    text: 'ยกเลิกรายการแล้ว',
                    confirmButtonText: 'ตกลง',
                    // 'Cancelled',
                    // 'Your imaginary file is safe :)',
                    // 'error'
                })
            }
        })
    }

    const searchCar = async () => {
        try {
            let res = await POST(SEARCHCAR, { text_search: text });
            if (res.success) {
                setData(res.result);
            } else {
                alert(res.message);
            }
        } catch (error) {
            alert("error", error);
        }
    };
    const columns = useMemo(
        () => [
            // {
            //     accessorKey: 'car_part_id',
            //     header: 'ID',
            //     enableColumnOrdering: false,
            //     enableEditing: false, //disable editing on this column
            //     enableSorting: false,
            //     disabled: true,
            //     size: 80,
            // },
            {
                accessorKey: 'name',
                header: 'Merchant Name',
                size: 50,
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'provider',
                header: 'Provider',
                size: 50,
                accessorFn: (e) => (e.credentials[0].provider),
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                header: 'Fee In',
                inputType: 'number',
                size: 50,
                accessorFn: (e) => {
                    const totalFeeIn = e.setting[0].providerFeeIn + e.setting[0].agentFeeIn + e.setting[0].systemFeeIn;
                    return thousandsSeparator(totalFeeIn)
                },
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                header: 'Fee Out',
                inputType: 'number',
                size: 50,
                accessorFn: (e) => {
                    const totalFeeOut = e.setting[0].providerFeeOut + e.setting[0].agentFeeOut + e.setting[0].systemFeeOut;
                    return thousandsSeparator(totalFeeOut);
                },
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                header: 'Agent',
                size: 100,
                accessorFn: (e) => e.setting[0].agent?.name ?? e.setting[0].agent,
            },
            {
                accessorKey: 'balance',
                header: 'Wallet',
                inputType: 'number',
                size: 50,
                accessorFn: (e) => e.balances[0] ? (thousandsSeparator(e.balances[0]?.balance)) : 0,
                Cell: row => <div style={{ textAlign: "right" }}>{row.renderedCellValue}</div>
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'balance withdraw',
                header: 'balance withdraw',
                inputType: 'number',
                size: 50,
                accessorFn: (e) => e.balances[0] ? (thousandsSeparator(e.balances[0].balanceWithdraw)) : 0,
                Cell: row => <div style={{ textAlign: "right" }}>{row.renderedCellValue}</div>
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },

        ],
        [],
    );

    useEffect(() => {
        getMerchant();
    }, []);


    return (
        <>
            {/* ค้นหา */}
            <Box display="flex" justifyContent="center">
                <Paper sx={{ width: '90%', py: 3 }} elevation={0}>
                    {/* <Typography variant="caption" color="#2996D1" fontSize={18} display="flex" fontWeight="bold" alignItems="center">
                        ค้นหา
                    </Typography>
                    <Grid item xs={12} sx={{ py: 2 }}>
                        <TextField
                            id="outlined-basic"
                            label="ชื่อร้านค้า"
                            variant="outlined"
                            placeholder='ร้านค้า'

                            required
                            size='medium'
                            fullWidth
                            value={text}
                            onChange={(e) => setText(e.currentTarget.value)}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    searchCar("");
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <SearchTextIcon onClick={searchCar} />
                                        </IconButton >
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid> */}
                    <MaterialReactTable
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                muiTableHeadCellProps: {
                                    align: 'center',
                                },
                                size: 10,
                            },
                        }}
                        columns={columns}
                        data={data}
                        enableColumnOrdering
                        enableEditing={false}
                        enableRowNumbers
                        rowNumberMode="original"
                        enableRowActions
                        renderRowActions={({ row }) => (
                            <Box textAlign="center">
                                <IconButton onClick={() => handleRoute(`/Merchant/edit/${row.original.id}`)}>
                                    <ModeIcon />
                                </IconButton>
                            </Box>
                        )}

                    />
                </Paper>

            </Box>

            {/* List */}



        </>
    )
}

export default CarTable;